import { useState, useEffect } from 'react';
import {
  Container, Row, Col, InputGroup,
  FormControl, Button, Dropdown, DropdownButton,
  Accordion, AccordionBody, Stack,
} from 'react-bootstrap';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { GiMagicLamp } from 'react-icons/gi';
import { useTranslation, getLanguage } from '../i18n';
import Header from './Header';
import { APIClient } from '../lib/APIClient';
import Jenny from '../images/jenny.png';
import Loading from './Loading';
import { addPendingBookId } from '../lib/Library';

const DEFAULT_FIELD_DATA = {
  plot: '',
  characters: '',
  durationMinutes: '',
  childYearsOld: '',
  inspirationBook: '',
  inspirationAuthor: '',
  storytellingModelIndex: 0,
  artModelIndex: 0,
};

const STORYTELLING_MODELS = [['Anthropic Claude', 'claude'], ['Amazon Nova', 'nova']];
const ART_MODELS = [['Stable Diffusion', 'stable-diffusion'], ['Amazon Nova', 'nova']];

const STORYTELLING_MODEL_INDEX_MAP = STORYTELLING_MODELS
  .reduce((prev, curr, index) => ({ ...prev, [curr[1]]: index }), {}) as {[key: string]: number};

const ART_MODEL_INDEX_MAP = ART_MODELS
  .reduce((prev, curr, index) => ({ ...prev, [curr[1]]: index }), {}) as {[key: string]: number};

const apiClient = new APIClient();

function CreateBook() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [fieldData, setFieldData] = useState(DEFAULT_FIELD_DATA);
  const [isLoading, setIsLoading] = useState(true);
  const [bookId, setBookId] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const fromBookId = searchParams.get('fromBookId');

  useEffect(() => {
    new Promise((resolve, reject) => {
      if (fromBookId) {
        apiClient.getBookData(fromBookId).then((bookData) => {
          if (bookData) {
            setFieldData({
              plot: bookData.input.plot || '',
              characters: bookData.input.characters || '',
              durationMinutes: bookData.input.durationMinutes ? bookData.input.durationMinutes.toString() : '',
              childYearsOld: bookData.input.childYearsOld ? bookData.input.childYearsOld.toString() : '',
              inspirationBook: bookData.input.inspirationBook || '',
              inspirationAuthor: bookData.input.inspirationAuthor || '',
              storytellingModelIndex: STORYTELLING_MODEL_INDEX_MAP[
                bookData.input.storytellingModel || 'claude'],
              artModelIndex: ART_MODEL_INDEX_MAP[
                bookData.input.artModel || 'stable-diffusion'],
            });
          } else {
            console.log(`Book with ID '${fromBookId}' not found`);
          }
          setIsLoading(false);
          resolve();
        }).catch(reject);
      } else {
        setIsLoading(false);
        resolve();
      }
    }) as Promise<void>;
  }, [fromBookId]);

  const handleFieldDataChange = (event: {target: { name: string, value: string | number }}) => {
    const { name, value } = event.target;
    setFieldData((prevFieldData) => ({
      ...prevFieldData,
      [name]: value,
    }));
  };

  const onSubmit = async () => {
    const {
      plot,
      characters,
      durationMinutes,
      childYearsOld,
      inspirationBook,
      inspirationAuthor,
      storytellingModelIndex,
      artModelIndex,
    } = fieldData;

    if (!plot || !plot.trim()) {
      alert(t('mustProvidePlot'));
      return;
    }

    const finalPlot = plot;
    let finalCharacters: string | null = null;
    let finalDurationMinutes: number | null = null;
    let finalChildYearsOld: number | null = null;
    let finalInspirationBook: string | null = null;
    let finalInspirationAuthor: string | null = null;

    if (characters && characters.trim()) {
      finalCharacters = characters.trim();
    }

    if (durationMinutes) {
      finalDurationMinutes = parseInt(durationMinutes, 10);
      if (Number.isNaN(finalDurationMinutes)) {
        alert(t('durationInvalid'));
        return;
      }
    }

    if (childYearsOld) {
      finalChildYearsOld = parseInt(childYearsOld, 10);
      if (Number.isNaN(finalChildYearsOld)) {
        alert(t('ageInvalid'));
        return;
      }
    }

    if (inspirationBook && inspirationBook.trim()) {
      finalInspirationBook = inspirationBook.trim();
    }
    if (inspirationAuthor && inspirationAuthor.trim()) {
      finalInspirationAuthor = inspirationAuthor.trim();
    }

    const newBookId = await apiClient.createBook(
      finalPlot,
      finalCharacters,
      finalDurationMinutes,
      finalChildYearsOld,
      finalInspirationBook,
      finalInspirationAuthor,
      STORYTELLING_MODELS[storytellingModelIndex][1],
      ART_MODELS[artModelIndex][1],
    );

    setBookId(newBookId);

    addPendingBookId(newBookId);

    navigate(`/book/${newBookId}`);
  };

  let body = <Loading />;
  if (!isLoading) {
    body = (
      <>
        <InputGroup className="mb-2">
          <InputGroup.Text>{t('plot')}</InputGroup.Text>
          <FormControl
            value={fieldData.plot}
            placeholder={t('enterPlot')}
            name="plot"
            onChange={handleFieldDataChange}
          />
        </InputGroup>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <i>{t('expandForMore')}</i>
            </Accordion.Header>
            <AccordionBody>
              <Stack>
                <InputGroup className="mb-2">
                  <InputGroup.Text>{t('characters')}</InputGroup.Text>
                  <FormControl
                    value={fieldData.characters}
                    placeholder={t('enterCharacters')}
                    name="characters"
                    onChange={handleFieldDataChange}
                  />
                </InputGroup>
                <InputGroup className="mb-2">
                  <InputGroup.Text>{t('durationMinutes')}</InputGroup.Text>
                  <FormControl
                    value={fieldData.durationMinutes}
                    placeholder={t('enterDuration')}
                    name="durationMinutes"
                    onChange={handleFieldDataChange}
                  />
                </InputGroup>
                <InputGroup className="mb-2">
                  <InputGroup.Text>{t('childAge')}</InputGroup.Text>
                  <FormControl
                    value={fieldData.childYearsOld}
                    placeholder={t('enterChildAge')}
                    name="childYearsOld"
                    onChange={handleFieldDataChange}
                  />
                </InputGroup>
                <InputGroup className="mb-2">
                  <InputGroup.Text>{t('inspiredByBook')}</InputGroup.Text>
                  <FormControl
                    value={fieldData.inspirationBook}
                    placeholder={t('enterInspiredBook')}
                    name="inspirationBook"
                    onChange={handleFieldDataChange}
                  />
                </InputGroup>
                <InputGroup className="mb-2">
                  <InputGroup.Text>{t('storytellingModel')}</InputGroup.Text>
                  <DropdownButton
                    title={STORYTELLING_MODELS[fieldData.storytellingModelIndex][0]}
                  >
                    {
                      STORYTELLING_MODELS.map((storytellingModel, index) => (
                        <Dropdown.Item
                          key={storytellingModel[1]}
                          onClick={
                            () => handleFieldDataChange(
                              {
                                target: {
                                  name: 'storytellingModelIndex',
                                  value: index,
                                },
                              },
                            )
                          }
                        >
                          {storytellingModel[0]}
                        </Dropdown.Item>
                      ))
                    }
                  </DropdownButton>
                </InputGroup>
                <InputGroup className="mb-2">
                  <InputGroup.Text>{t('storyArtModel')}</InputGroup.Text>
                  <DropdownButton
                    title={ART_MODELS[fieldData.artModelIndex][0]}
                  >
                    {
                      ART_MODELS.map((artModel, index) => (
                        <Dropdown.Item
                          key={artModel[1]}
                          onClick={
                            () => handleFieldDataChange(
                              {
                                target: {
                                  name: 'artModelIndex',
                                  value: index,
                                },
                              },
                            )
                          }
                        >
                          {artModel[0]}
                        </Dropdown.Item>
                      ))
                    }
                  </DropdownButton>
                </InputGroup>
              </Stack>
            </AccordionBody>
          </Accordion.Item>
        </Accordion>
        <Button
          variant="success"
          onClick={onSubmit}
          disabled={!!bookId}
          className="mt-3 btn-lg"
        >
          <GiMagicLamp />
          {' '}
          {t('createStory')}
        </Button>
      </>
    );
  }

  return (
    <div className="CreateBook" style={{ direction: getLanguage() === 'he' ? 'rtl' : 'ltr' }}>
      <Header />
      <div className="m-2">
        <h1>{t('createNewBook')}</h1>
        <Container fluid>
          <Row>
            <Col md="auto">
              <img src={Jenny} alt="Jenny" width="300" />
            </Col>
            <Col>{body}</Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default CreateBook;
