interface Translations {
  [key: string]: {
    [key: string]: string;
  };
}

export const translations: Translations = {
  en: {
    createNewBook: 'Create a new book',
    plot: 'Plot',
    enterPlot: 'Enter the plot for your story',
    expandForMore: 'Expand for more details (optional)',
    characters: 'Characters',
    enterCharacters: 'Who is your story about (separate with \'and\')',
    durationMinutes: 'Duration in Minutes',
    enterDuration: 'How long should your story be?',
    childAge: 'Child Age',
    enterChildAge: 'How old (in years) is the target audience?',
    inspiredByBook: 'Inspired By Book',
    enterInspiredBook: 'Title of book to use as inspiration',
    storytellingModel: 'Storytelling Model',
    storyArtModel: 'Story Art Model',
    createStory: 'Create Story',
    library: 'Library',
    newStory: 'New Story',
    poweredBy: 'Powered by',
    letsRead: 'Let\'s read',
    removeStory: 'Remove Story',
    emptyLibrary: 'Empty Library',
    noStoriesSaved: 'It seems you don\'t have any stories saved.',
    createNewOne: 'That\'s OK - use your imagination to create a new one!',
    removeBookTitle: 'Remove Book',
    removeBookConfirm: 'Are you sure you want to remove this story from your library?',
    removeBookNote: 'People that the story was shared with can still view it.',
    cancel: 'Cancel',
    remove: 'Remove',
    youHave: 'You have ',
    storiesInLibrary: ' stories in your library. Click on one below to get more details and read it again. ',
    story: 'You have a story in your library. Click on it below to get more details and read it again.',
    searchLibrary: 'Search library',
    showing: 'showing',
    createdByMe: 'Created by me',
    readByMe: 'Read by me',
    createAnotherLike: 'Create another story like this one...',
    mustProvidePlot: 'Must provide a plot',
    durationInvalid: 'Duration minutes is an invalid number',
    ageInvalid: 'Child age is an invalid number',
    statusInitializing: 'That\'s a great idea, let me get started',
    statusCreating: 'I\'m thinking...',
    statusTranscribing: 'I\'m recording my voice and drawing some pictures...',
    statusReady: 'All set!',
    statusQueued: 'I\'m busy helping others right now, I\'ll be with you in a few moments. Sit tight and get the hot chocolate ready!',
    pageNumber: 'Page',
    pageOf: 'of',
    waitForSnack: 'This could take a while, go grab a snack and come back in a few minutes',
    transcribedCount: 'Transcribed {0} of {1} recordings',
    illustratedCount: 'Illustrated {0} of {1} images',
  },
  he: {
    createNewBook: 'יצירת ספר חדש',
    plot: 'עלילה',
    enterPlot: 'הכניסו את העלילה עבור הסיפור ',
    expandForMore: 'הרחיבו לפרטים נוספים (אופציונלי)',
    characters: 'דמויות',
    enterCharacters: 'על מי הסיפור ',
    durationMinutes: 'משך בדקות',
    enterDuration: 'כמה זמן הסיפור צריך להיות?',
    childAge: 'גיל הילד',
    enterChildAge: 'מה הגיל (בשנים) של קהל היעד?',
    inspiredByBook: 'בהשראת הספר',
    enterInspiredBook: 'כותרת ספר/סרט שישמש כהשראה',
    storytellingModel: 'מודל ליצירת הסיפור',
    storyArtModel: 'מודל ליצירת תמונות',
    createStory: 'צור סיפור',
    library: 'ספרייה',
    newStory: 'סיפור חדש',
    poweredBy: 'מופעל על ידי',
    letsRead: 'בואו נקרא',
    removeStory: 'הסר סיפור',
    emptyLibrary: 'ספרייה ריקה',
    noStoriesSaved: 'נראה שאין לכם סיפורים שמורים.',
    createNewOne: 'זה בסדר - השתמשו בדמיון שלכם ליצור אחד חדש!',
    removeBookTitle: 'הסר ספר',
    removeBookConfirm: 'האם אתם בטוחים שברצונכם להסיר סיפור זה מהספרייה שלכם?',
    removeBookNote: 'אנשים שהסיפור שותף איתם עדיין יכולים לצפות בו.',
    cancel: 'ביטול',
    remove: 'הסר',
    youHave: ' יש לכם ',
    storiesInLibrary: ' סיפורים בספרייה שלכם. לחצו על אחד מהם לקבלת פרטים נוספים ולקריאה חוזרת. ',
    story: 'יש לכם סיפור אחד בספרייה שלך. לחצו עליו לקבלת פרטים נוספים ולקריאה חוזרת.',
    searchLibrary: 'חפש בספרייה',
    showing: 'מציג',
    createdByMe: ' נוצר על ידי לפני',
    readByMe: 'נקרא על ידי לפני ',
    createAnotherLike: 'צור סיפור נוסף כמו זה...',
    mustProvidePlot: 'חובה לספק עלילה',
    durationInvalid: 'משך הדקות אינו מספר תקין',
    ageInvalid: 'גיל הילד אינו מספר תקין',
    statusInitializing: 'זה רעיון נהדר, בואו נתחיל',
    statusCreating: 'אני חושבת...',
    statusTranscribing: 'אני מקליטה את קולי ומציירת כמה תמונות...',
    statusReady: 'הכל מוכן!',
    statusQueued: 'אני עסוקה בעזרה לאחרים כרגע, אהיה איתכם בעוד כמה רגעים. שבו בנוח והכינו שוקו חם!',
    pageNumber: 'עמוד',
    pageOf: 'מתוך',
    waitForSnack: 'זה יכול לקחת זמן, לכו להביא נשנוש ותחזרו בעוד כמה דקות',
    transcribedCount: 'הוקלטו {0} מתוך {1} קטעי קול',
    illustratedCount: 'צוירו {0} מתוך {1} תמונות',
  },
};
