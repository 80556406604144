import { Navbar, Container, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { GiMagicLamp } from 'react-icons/gi';
import { IoLibraryOutline } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { reportPageView } from '../lib/CloudwatchRum';
import { t, getLanguage } from '../i18n';
import LanguageToggle from './LanguageToggle';

function Header() {
  const location = useLocation();
  useEffect(() => {
    reportPageView(location.pathname);
  }, [location]);

  return (
    <Navbar variant="dark" bg="primary" style={{ direction: 'ltr' }}>
      <Container fluid>
        <LinkContainer to="/">
          <Navbar.Brand>
            Jenny
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="bas-c-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/">
              <Nav.Link>
                <GiMagicLamp style={{ fontSize: '30px' }} />
                {' '}
                {t('newStory')}
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/library">
              <Nav.Link>
                <IoLibraryOutline style={{ fontSize: '30px' }} />
                {' '}
                {t('library')}
              </Nav.Link>
            </LinkContainer>
          </Nav>
          <Navbar.Text style={{ direction: getLanguage() === 'he' ? 'rtl' : 'ltr' }}>
            <i className="m-2">
              {`${t('poweredBy')} `}
              <a href="https://aws.amazon.com/generative-ai" target="_blank" rel="noreferrer">Amazon Web Services</a>
            </i>
          </Navbar.Text>
          <LanguageToggle />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
