import { translations } from './translations';
import { useLanguage } from './LanguageContext';

let currentLanguage = 'en';

export const setLanguage = (lang: string) => {
  currentLanguage = lang;
};

export const getLanguage = () => currentLanguage;

// Raw translation function (without React integration)
const translateText = (key: string, lang: string): string => translations[lang][key] || key;

// React hook for translations that will re-render when language changes
export const useTranslation = () => {
  const { language } = useLanguage();

  return {
    t: (key: string) => translateText(key, language),
  };
};

// Legacy t function for non-React contexts
export const t = (key: string): string => translateText(key, currentLanguage);

// Initialize default language based on browser settings
const initializeLanguage = () => {
  const browserLang = navigator.language;
  if (browserLang.startsWith('he')) {
    setLanguage('he');
  }
};

initializeLanguage();
