import {
  createContext,
  useContext,
  useState,
  useMemo,
  ReactNode,
} from 'react';
import { setLanguage as i18nSetLanguage, getLanguage } from './index';

interface LanguageContextType {
  language: string;
  setLanguage: (lang: string) => void;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export function LanguageProvider({ children }: { children: ReactNode }) {
  const [language, setLang] = useState(getLanguage());
  const [, forceUpdate] = useState({});

  const setLanguage = (lang: string) => {
    i18nSetLanguage(lang);
    setLang(lang);
    forceUpdate({}); // Force re-render of all components using translations
  };

  return (
    <LanguageContext.Provider value={useMemo(() => ({ language, setLanguage }), [language])}>
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}
