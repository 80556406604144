import React from 'react';
import { Button } from 'react-bootstrap';
import { useLanguage } from '../i18n/LanguageContext';

function LanguageToggle() {
  const { language, setLanguage } = useLanguage();

  const toggleLanguage = () => {
    setLanguage(language === 'en' ? 'he' : 'en');
  };

  return (
    <Button
      variant="light"
      onClick={toggleLanguage}
      style={{ marginLeft: '10px' }}
    >
      {language === 'en' ? 'עברית' : 'English'}
    </Button>
  );
}

export default LanguageToggle;
